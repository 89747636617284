import { graphql, useStaticQuery } from 'gatsby'

export const TermsData = () => {
  const { wdwordpress: { page: { termsofuse_contents } } } = useStaticQuery(graphql`
  {
    wdwordpress {
      page(id: "cGFnZToyMzU5") {
        termsofuse_contents {
          headTitle {
            en
            sv
          }
          list {
            text {
              en
              sv
            }
            title {
              en
              sv
            }
          }
        }
      }
    }
  }
`
  )
  return termsofuse_contents
}