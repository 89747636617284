import React from "react"
import { useSelector } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TermsData } from "../queries/termsOfUse"
const TermsOfUse = () => {


  const { language } = useSelector(state => state.global)

  const data = TermsData()

  const { headTitle, list } = data

  return (
    <Layout>
      <SEO title="Terms of use" pageId="cGFnZToyMzU5" />
      <div className="banner about_banner terms_of">
        <div className="container">
          <div className="banner_txt">
            <div className="w-100">
              <h1 className="text-center">{headTitle[language]}</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="career_detail terms">
        <div className="container p-0">
          <div className="c_detail">
            <div className="c_detail_block w-100">
              {list.slice(0, 1).map(item => (
                <>
                  <h5 className="pt-0">{item.title[language]}</h5>
                  <p>{item.text[language]}</p>
                </>
              ))}
              {list.slice(1).map(item => (
                <>
                  <h5>{item.title[language]}</h5>
                  <p>{item.text[language]}</p>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsOfUse
